import moment from "moment";
import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { addSupportCommentAPI } from '../../../apiConfig/apiCall';
import avatar8 from "../../../assets/images/users/avatar-8.jpg";
import { toast } from "react-toastify";

const TicketDescription = ({ ticketDetails }) => {

    const IMAGE_URL = process.env.REACT_APP_API_IMAGE_URL;

    console.log(typeof (ticketDetails), ticketDetails)

    const [comment, setComment] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleCommentChange = (e) => setComment(e.target.value);
    const handleImageChange = (e) => setImage(e.target.files[0]);

    const handlePostComment = async () => {
        if (!comment.trim()) {
            alert("Comment is required!");
            return;
        }

        const formData = new FormData();
        formData.append('id', ticketDetails.id);
        formData.append('comment', comment);
        if (image) {
            formData.append('image', image);
        }

        try {
            setLoading(true);
            const apiResponse = await addSupportCommentAPI(formData);
            if (apiResponse.status === 201) {
                toast.success("Comment added successfully");
            } else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.error("Error posting comment:", error);
            alert("An error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Col xxl={9}>
                <Card>
                    <CardBody className="p-4">
                        <h6 className="fw-semibold text-uppercase mb-3">Ticket Discripation</h6>
                        <p className="text-muted mb-0">{ticketDetails.overview}</p>
                    </CardBody>
                    <CardBody className="p-4">
                        <h5 className="card-title mb-4">Comments</h5>

                        <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3">
                            {ticketDetails.comments && ticketDetails.comments.length > 0 ? (
                                ticketDetails.comments.map((comment, index) => (
                                    <div className="d-flex mb-4" key={index}>
                                        <div className="flex-shrink-0">
                                            <img
                                                src={comment.avatar || avatar8}
                                                alt={`${comment.firstName} ${comment.lastName}`}
                                                className="avatar-xs rounded-circle material-shadow"
                                            />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="fs-13">
                                                {comment.firstName} {comment.lastName}
                                                <small className="text-muted">
                                                    {" - "}
                                                    {moment(comment.date).isValid() ? moment(comment.date).format("DD MMM YYYY - hh:mmA") : "Invalid date"}
                                                </small>
                                            </h5>
                                            <p className="text-muted">{comment.comment}</p>

                                            {comment.imagePath ? (
                                                <Row className="g-2 mb-3">
                                                    <Col lg={2} sm={2} xs={6}>
                                                        <div className="bg-light p-2">
                                                        <img
                                                            src={IMAGE_URL + comment.imagePath}
                                                            alt="Comment attachment"
                                                            className="img-fluid rounded material-shadow"
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted">No comments available.</p>
                            )}
                        </SimpleBar>

                        <form className="mt-3">
                            <Row className="g-3">
                                <Col lg={12}>
                                    <Label htmlFor="comment" className="form-label">Leave a Comment</Label>
                                    <textarea
                                        className="form-control bg-light border-light"
                                        id="comment"
                                        rows="3"
                                        placeholder="Enter comment"
                                        value={comment}
                                        onChange={handleCommentChange}
                                        style={{ height: '60px' }}
                                    ></textarea>
                                </Col>
                                <Col lg={12}>
                                    <Label htmlFor="imageUpload" className="form-label">Upload Image (Optional)</Label>
                                    <input
                                        type="file"
                                        id="imageUpload"
                                        className="form-control bg-light border-light"
                                        onChange={handleImageChange}
                                    />
                                </Col>
                                <Col lg={12} className="text-end">
                                    <Button
                                        className="btn btn-success"
                                        onClick={handlePostComment}
                                        disabled={loading}
                                    >
                                        {loading ? "Posting..." : "Post Comment"}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>

                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TicketDescription;