import { combineReducers } from "redux";

import LayoutReducer from "./layouts/reducer";
import UserDetailsReducer from "./UserDeatils/dashboardWidgetDataSlice";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    UserDetails: UserDetailsReducer,
});

export default rootReducer;