import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

import Section from './Section';
import TicketDescription from './TicketDescription';
import TicketDetails from './TicketDetails';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useParams } from 'react-router-dom';
import { getSingleSportTicketListAPI } from '../../../apiConfig/apiCall';


const ViewSuport = () => {

    const params = useParams();
    const ID = params.id;

    const [ticketDetails, setTicketDetails] = useState({});
    const getTicketDetails = async (ID) => {
        try {
            const apiResponse = await getSingleSportTicketListAPI(ID);
            if (apiResponse.status === 200) {
                setTicketDetails(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        getTicketDetails(ID);
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'View Support'} pageTitle={"Dashboard"} />
                    <Row>
                        <Section ticketDetails={ticketDetails}/>
                    </Row>
                    <Row>
                        <TicketDescription ticketDetails={ticketDetails}/>
                        <TicketDetails ticketDetails={ticketDetails}/>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ViewSuport;