import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//import images
import scanBitLogo from "../../assets/xzlenzImages/scanbitlogo.png";
import xzlenzLogo from "../../assets/xzlenzImages/xzlenzDark.png";
import reportingToolLogo from "../../assets/xzlenzImages/reporting-tool-logo.png";
import xeonpayLogo from "../../assets/xzlenzImages/xeonpayLogo.png";
import dhanushLogo from "../../assets/xzlenzImages/dhanushguard.png";

import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const WebAppsDropdown = () => {

    const jwtToken = Cookies.get('x-http-core');
    const username = Cookies.get('user');

    const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
    const toggleWebAppDropdown = () => {
        setIsWebAppDropdown(!isWebAppDropdown);
    };

    const XZLENZ_URL = process.env.REACT_APP_XZLENZ_URL;
    const SCANBIT_URL = process.env.REACT_APP_SCANBIT_URL;
    const REPORTING_TOOL_URL = process.env.REACT_APP_REPORTING_TOOL_URL;
    const DHANUSH_GUARD_URL = process.env.REACT_APP_DHANUSH_GUARD_URL;
    const XEON_PAY_URL = process.env.REACT_APP_XEON_PAY_URL;

    return (
        <React.Fragment>
            <Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-category-alt fs-22'></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
                    <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0 fw-semibold fs-15"> Our Apps </h6>
                            </Col>
                        </Row>
                    </div>

                    <div className="p-2">
                        <div className="row g-0">
                            <Col lg={4}>
                                <Link className="dropdown-icon-item" target='_blank' to={`${XZLENZ_URL}/dashboard/?token=${encodeURIComponent(jwtToken)}&username=${encodeURIComponent(username)}`}>
                                    <img src={xzlenzLogo} alt="..." />
                                    <span>Xzlenz</span>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link className="dropdown-icon-item" target='_blank' to={SCANBIT_URL}>
                                    <img src={scanBitLogo} alt="..." />
                                    <span>Scanbit</span>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link className="dropdown-icon-item" target='_blank' to={REPORTING_TOOL_URL}>
                                    <img src={reportingToolLogo} alt="..." />
                                    <span>ReportingTool</span>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link className="dropdown-icon-item" target='_blank' to={DHANUSH_GUARD_URL}>
                                    <img src={dhanushLogo} alt="..." />
                                    <span>DhanushGuard</span>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link className="dropdown-icon-item" target='_blank' to={XEON_PAY_URL}>
                                    <img src={xeonpayLogo} alt="..." />
                                    <span>XeonPay</span>
                                </Link>
                            </Col>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default WebAppsDropdown;