import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import withRouter from "../../Components/Common/withRouter";
import authLogoXzlenz from '../../assets/xzlenzImages/xzlenz-login.png';
import { loginUser, verifyOtp } from '../../apiConfig/apiCall'; // Add verifyOtp API call
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

const Login = () => {

    const XZLENZ_SUPER_URL = process.env.REACT_APP_XZLENZ_SUPER_URL;

    useEffect(() => {
        const canvas = document.getElementById('universe');
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let stars = [];
        const numStars = 150;
        for (let i = 0; i < numStars; i++) {
            const isFast = Math.random() > 0.7;
            const isSuperFast = Math.random() > 0.9;
            const angle = isSuperFast && Math.random() > 0.5 ? Math.PI / 4 : 0;

            const speed = isSuperFast
                ? Math.random() * 5 + 3
                : isFast
                    ? Math.random() * 2 + 1
                    : Math.random() * 0.5 + 0.25;

            stars.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 2,
                speedX: speed * Math.cos(angle),
                speedY: speed * Math.sin(angle),
            });
        }

        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'white';

            stars.forEach(star => {
                ctx.beginPath();
                ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
                ctx.fill();
                ctx.closePath();

                star.x += star.speedX;
                star.y += star.speedY;

                if (star.x < 0) star.x = canvas.width;
                if (star.x > canvas.width) star.x = 0;
                if (star.y < 0) star.y = canvas.height;
                if (star.y > canvas.height) star.y = 0;
            });

            requestAnimationFrame(animate);
        }

        animate();

        window.addEventListener('resize', () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });

        return () => {
            window.removeEventListener('resize', () => { });
        };
    }, []);

    const navigate = useNavigate();

    const [passwordShow, setPasswordShow] = useState(false);
    const [inputValues, setInputValues] = useState({ username: "", password: "" });
    const [otp, setOtp] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otpStep, setOtpStep] = useState(false);

    const handleChange = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    }

    const handleOtpChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,6}$/.test(value)) {
            setOtp(value);
        }
    };


    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
    }

    const handleSubmit = async (event) => {
        try {
            const allCookies = Cookies.get();
            Object.keys(allCookies).forEach(cookieName => {
                Cookies.remove(cookieName);
            });
            event.preventDefault();
            setLoading(true);

            const { username, password } = inputValues;

            if (username === "") {
                toast.error("Username is required");
                setLoading(false);
                return;
            } else if (password === "") {
                toast.error("Password is required");
                setLoading(false);
                return;
            }

            const data = { username, password, rememberMe };

            const apiResponse = await loginUser(data);

            if (apiResponse === undefined) {
                toast.error("API not working");
                setLoading(false);
            } else if (apiResponse.status === 200) {
                setOtpStep(true);
                toast.success("SSO ID and password verified. Enter OTP.");
            } else {
                toast.error("Invalid login details");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleVerifyOtp = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);

            if (otp === "") {
                toast.error("OTP is required");
                setLoading(false);
                return;
            }

            const { username } = inputValues;
            if (username === "") {
                toast.error("Username is missing");
                setLoading(false);
                return;
            }

            const apiResponse = await verifyOtp({ otp, username });
            if (apiResponse.status === 200) {
                if (apiResponse.data.roles === 'SUPER') {
                    const jwtToken = apiResponse.data.jwtToken;
                    const username = apiResponse.data.username;
                    toast.success("Successfully logged in");
                    const targetUrl = `${XZLENZ_SUPER_URL}/?token=${encodeURIComponent(jwtToken)}&username=${encodeURIComponent(username)}`;
                    window.location.href = targetUrl;
                }
                else {
                    Cookies.set('x-http-core', apiResponse.data.jwtToken);
                    Cookies.set('user', apiResponse.data.username);
                    toast.success("Successfully logged in");
                    navigate('/dashboard')
                }
            } else {
                toast.error(apiResponse.data || "Invalid OTP or Username");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="login-page">
            <div className="auth-content">
                <Form>
                    <div className="logo">
                        <img className="img-fluid" src={authLogoXzlenz} alt="Logo" />
                    </div>
                    <h1 className="title py-2">{otpStep ? "Verify OTP" : "Please sign in"}</h1>

                    {!otpStep ? (
                        <>
                            <FormGroup floating>
                                <Input id="username" name="username" placeholder="Email" type="text" value={inputValues.username} onChange={handleChange} />
                                <Label for="username" style={{ fontSize: '.9rem' }}>SSO ID</Label>
                            </FormGroup>

                            <div className='position-relative'>
                                <FormGroup floating>
                                    <Input id="password" name="password" placeholder="Password" type={passwordShow ? "text" : "password"} value={inputValues.password} onChange={handleChange} />
                                    <Label for="password" style={{ fontSize: '.9rem' }}>Password</Label>
                                </FormGroup>
                                <button
                                    className="btn btn-link fs-6 position-absolute text-decoration-none text-dark material-shadow-none"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setPasswordShow(!passwordShow)}
                                    style={{ top: '.7rem', right: '.2rem' }}>
                                    <i className="ri-eye-fill align-middle"></i>
                                </button>
                            </div>
                        </>
                    ) : (
                        <FormGroup floating>
                            <Input id="otp" name="otp" placeholder="OTP" type="text" value={otp} onChange={handleOtpChange} />
                            <Label for="otp" style={{ fontSize: '.9rem' }}>Enter OTP</Label>
                        </FormGroup>
                    )}

                    <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={otpStep ? handleVerifyOtp : handleSubmit} disabled={loading}
                            style={{ background: "#2f55d4" }}
                        >
                            {loading ? (
                                <Spinner size="sm" className='me-2' />
                            ) : (
                                otpStep ? "Verify OTP" : "Sign In"
                            )}
                        </Button>
                    </div>

                    {!otpStep && (
                        <div className="form-check d-flex justify-content-between align-items-center mt-3">
                            <div>
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="auth-remember-check"
                                    checked={rememberMe}
                                    onChange={handleCheckboxChange}
                                />
                                <Label className="form-check-label" htmlFor="auth-remember-check">
                                    Remember me
                                </Label>
                            </div>
                            <div className="float-end">
                                <Link to="/forgot-password" className="text-dark">
                                    Forgot password?
                                </Link>
                            </div>
                        </div>
                    )}

                    <div className="text-center mt-3">
                        <p className="text-muted mb-2 fs-6">XzLenz</p>
                        <p className="mb-0 text-muted fs-6">Compliance Excellence, Unveiled</p>
                    </div>
                </Form>
            </div>
            <div className="universe">
                <div className="universe-container">
                    <canvas id="universe"></canvas>
                </div>
            </div>
        </Container>
    );
};

export default withRouter(Login);