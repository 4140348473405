import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import scanbitLogo from '../../assets/xzlenzImages/scanbitlogo.png';
import xzlenzLogo from '../../assets/xzlenzImages/xzlenzDark.png';
import reportingToolLogo from '../../assets/xzlenzImages/reporting-tool-logo.png';
import dhanushLogo from '../../assets/xzlenzImages/dhanushguard.png';
import xeonpayLogo from "../../assets/xzlenzImages/xeonpayLogo.png";
import Cookies from 'js-cookie';

const CardItem = ({ logo, title, description, link }) => (
  <Col lg={4}>
    <Card className="overflow-hidden">
      <div className="pt-2 px-3">
        <img src={logo} alt={title} style={{ width: '13rem', height: '5rem', objectFit: 'contain' }} />
      </div>
      <CardBody className="bg-marketplace d-flex">
        <div className="flex-grow-1">
          <h4 className="fs-18 lh-base mb-0">
            Explore <span className="text-success">{title}</span>
          </h4>
          <p className="mb-0 mt-2 pt-1 text-muted">{description}</p>
          <div className="d-flex gap-3 mt-4">
            <Link to={link} className="btn btn-primary" target='_blank'>Visit Now</Link>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default function CompanyCard() {

  const jwtToken = Cookies.get('x-http-core');
  const username = Cookies.get('user');

  const XZLENZ_URL = process.env.REACT_APP_XZLENZ_URL;
  const SCANBIT_URL = process.env.REACT_APP_SCANBIT_URL;
  const REPORTING_TOOL_URL = process.env.REACT_APP_REPORTING_TOOL_URL;
  const DHANUSH_GUARD_URL = process.env.REACT_APP_DHANUSH_GUARD_URL;
  const XEON_PAY_URL = process.env.REACT_APP_XEON_PAY_URL;


  const cardsData = [
    {
      logo: xzlenzLogo,
      title: 'Xzlenz.',
      description: 'Auditing tool from cybercube',
      link: `${XZLENZ_URL}/?token=${encodeURIComponent(jwtToken)}&username=${encodeURIComponent(username)}`,
    },
    {
      logo: scanbitLogo,
      title: 'Scanbit.',
      description: 'Card Scanning tool from cybercube',
      link: SCANBIT_URL,
    },
    {
      logo: reportingToolLogo,
      title: 'Reporting Tool.',
      description: 'Reporting tool from cybercube',
      link: REPORTING_TOOL_URL,
    },
    {
      logo: dhanushLogo,
      title: 'Dhanush Guard.',
      description: 'Security tool from cybercube',
      link: `${DHANUSH_GUARD_URL}/?token=${encodeURIComponent(jwtToken)}&username=${encodeURIComponent(username)}`,
    },
    {
      logo: xeonpayLogo,
      title: 'Xeon Pay Tool.',
      description: 'Payment gateway tool from cybercube',
      link: XEON_PAY_URL,
    },
  ];

  return (
    <Col lg={12} className="mt-3">
      <Row>
        {cardsData.map((card, index) => (
          <CardItem
            key={index}
            logo={card.logo}
            title={card.title}
            description={card.description}
            link={card.link}
          />
        ))}
      </Row>
    </Col>
  );
}