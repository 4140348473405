import Cookies from 'js-cookie';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Error from '../../Components/Common/Error';
import Loader from '../../Components/Common/Loader';
import CompanyCard from "../../Components/Dashboard/CompanyCard";
import GreetMessage from "../../Components/Dashboard/GreetMessage";
import { getLoggedInUserDetails } from "../../slices/UserDeatils/thunk";
const Dashboard = () => {

  const username = Cookies.get('user');

  const dispatch = useDispatch();
  const { isLoading, isError, data } = useSelector(state => state.UserDetails);

  useEffect(() => {
    dispatch(getLoggedInUserDetails(username));
  }, [dispatch]);

  if (isLoading) return <Loader />

  if (isError) return <Error />

  const userDeatils = data;


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-3 pb-1">

            <GreetMessage userDeatils={userDeatils} />
            <CompanyCard />

          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;