import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard";
import ManageNotifications from "../pages/ManageNotifications";
import ManageSupport from "../pages/ManageSupport";
import NotFound from "../pages/Authentication/NotFound";
import ViewSupport from "../pages/ManageSupport/ViewSuport";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/notifications", component: <ManageNotifications /> },
  { path: "/support", component: <ManageSupport /> },
  { path: "/suppot-ticket/:id", component: <ViewSupport /> },
  { path: "*", component: <Navigate to="/not-found" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/not-found", component: <NotFound /> },
];

export { authProtectedRoutes, publicRoutes };
